<template>
  <Spinner v-show="!loaded"></Spinner>
  <div class="mt--8 pb-5 container" v-if="setting">
    <h1 class="text-center text-white mb-5">{{!this.isEditing ? 'ADD' : 'EDIT'}} SNMP Device</h1>
    <b-form v-if="setting">
      <div class="card bg-secondary border-0">
        <b-row class="setting-edit">
          <b-col lg="10" class="mx-auto">
            <b-row class="border-bottom sticky-top bg-white page-header">
              <div class="d-flex align-items-center">
                <b-link @click="$router.go(-1)">
                  <font-awesome-icon icon="arrow-left-long" />
                </b-link>
                <h5 class="text-uppercase p-0 my-3 mx-2 flex-grow-1">Back</h5>
                <b-button :disabled="this.invalid || (this.isEditing && !this.portsLoaded)" @click.prevent="saveSettings">{{!this.isEditing ? 'Add' : 'Update'}}</b-button>
              </div>
            </b-row>

            <b-row class="p-3">
              <b-col>
                  <b-form-group label="IP Address" label-for="ip" class="mb-3">
                      <b-form-input id="ip" name="ip" type="text"
                          v-model=" setting.ip " placeholder="0.0.0.0" required />
                  </b-form-group>
              </b-col>
            </b-row>
            <b-row class="p-3">
              <div lg="3">
                <b-form-group id="community" label="Community"
                  label-for="community">
                  <b-form-input class="w-100" id="community" v-model=" setting.community "
                      name="community" type="text" placeholder="e.g. public"
                      required></b-form-input>
                  </b-form-group>
              </div>
            </b-row>
            <div lg="3" class="p-3">
                <b-form-group id="pollFrequency" label="Poll Frequency (seconds)"
                  label-for="pollFrequency">
                  <b-form-input class="w-100" id="pollFrequency" v-model=" setting.pollFrequency "
                      name="pollFrequency" type="number" placeholder="1 - 1000000"
                      required></b-form-input>
                </b-form-group>
            </div>
            <h3 class="mt-3" v-if="this.isEditing">Monitored Ports</h3>
            <i v-if="this.isEditing">{{ "Only selected values with at least one bandwidth limit > 0 will be added and pushed to the top of this list" }}</i>
            <br/>
            <br/>
            <p v-if="!this.portsLoaded && this.isEditing">Fetching available ports...</p>
            <div v-if="availablePorts?.length">
              <b-row  v-for="(element, index) in availablePorts" :key="element" class="p-0 w-100">
                <b-row v-if="element?.speed >= 0">
                  <b-col class="col-1">
                      <b-form-group label="" label-for="no" class="mb-0 w-1">
                      <strong>{{ index + 1 }} </strong>
                      </b-form-group>
                  </b-col>
                  <b-col class="col-2">
                      <b-form-group label="Name" label-for="name" class="mb-0 w-1">
                      <strong>{{ element.name }}</strong>
                      </b-form-group>
                  </b-col>
                  <b-col class="col-2">
                      <b-form-group label="Speed(Gbps)" label-for="speed" class="mb-0">
                      {{ parseFloat(element.speed).toFixed(2) }}
                      </b-form-group>
                  </b-col>
                  <b-col class="col-2">
                      <b-form-group class="mb-0" label="In Limit(Gbps)" >
                          <b-form-input id="bandwidth_limit_in" name="bandwidth_limit_in" type="number"
                              v-model=" element.bandwidth_limit_in " placeholder="0" required />
                      </b-form-group>
                  </b-col>
                  <b-col class="col-2">
                      <b-form-group class="mb-0" label="Out Limit(Gbps)">
                          <b-form-input id="bandwidth_limit_out" name="bandwidth_limit_out" type="number"
                              v-model=" element.bandwidth_limit_out " placeholder="0" required />
                      </b-form-group>
                  </b-col>
                  <b-col class="col-2">
                      <b-form-group class="mb-0" label="Time Limit(seconds)">
                          <b-form-input id="announce_duration" name="announce_duration" type="number"
                              v-model=" element.announce_duration " placeholder="1" required />
                      </b-form-group>
                  </b-col>
                  <b-col class="col-1" label-for="active" label="Status">
                    <b-form-group label="Status" label-for="active" class="mb-0">
                      <b-form-checkbox
                          switch
                          name="active"
                          @change="handleChangeMonitoredPorts()"
                          :disabled="Number(element.bandwidth_limit_in) <= 0 && Number(element.bandwidth_limit_out) <= 0"
                          v-model="element.active"
                      />
                    </b-form-group>
                  </b-col>
                </b-row> 
              </b-row>
            </div>
            
            <h3 class="mt-5" v-if="this.isEditing">History</h3>
            <b-col lg="6" v-if="setting.created_at">
              <p><strong>
                Added:</strong>
                {{ _d(new Date(setting.created_at).toISOString()) }}
              </p>
            </b-col>
            <b-col lg="6" v-if="setting.updated_at">
              <p>
                <strong>Updated:</strong>
                {{ _d(new Date(setting.updated_at).toISOString()) }}
              </p>
            </b-col>
          </b-col>
        </b-row>
      </div>
    </b-form>
  </div>
</template>
<script>
  import SnmpDevicesService from "../../services/snmp-devices.service";
  import "vue3-toastify/dist/index.css";
  import { notify } from "../../helpers/index";
  import { getFullFormatDateFromISOString } from "../../helpers/index";
  import Spinner from "../../components/spinner/spinner.vue";

  export default {
    name: "SnmpDevice",
    components: {
      Spinner,
    },
    data: function () {
      return {
        setting: {
          id: '',
          ip: '',
          community: "public",
          pollFrequency: 0,
          monitoredPorts: []
        },
        availablePorts: [],
        isEditing: false,
        invalid: true,
        loaded: false,
        portsLoaded: false,
        portsLoading: false,
      };
    },
    computed: {
      currentUser() {
        return this.$store.state.auth.user;
      },
      snmpDeviceID() {
        return this.$route.params?.id;
      },
    },
    async mounted() {
      if(!!this.snmpDeviceID && this.$route.fullPath.indexOf('edit') > 0) {
        this.isEditing = true;
        await this.getOneSnmpDevice(this.snmpDeviceID);
        this.loaded = true
        await this.getAvailablePorts(this.snmpDeviceID);
      }
      this.$title = "SNMP Devices";
    },
    watch: {
      setting: {
        handler() {
          this.checkValid(this.setting);
        },
        deep: true,
        immediate: true
      }
    },
    methods: {
      _d(time) {
        return getFullFormatDateFromISOString(time, true);
      },
      checkValid(values) {
        this.invalid = false;
        if( values.ip.split('.')?.length != 4) this.invalid = true;
        if (values.pollFrequency && Number(values.pollFrequency) < 0) this.invalid = true;
        if (values.community && values.community?.length < 1) this.invalid = true;
        return this.invalid;
      },
      handleChangeCommunity(community){
        this.setting.community = community;
      },
      handleChangeMonitoredPorts(){
        this.availablePorts.sort((a, b) => {
                if (a.active && !b.active) {
                  return -1;
                }
                if (b.active && !a.active) {
                  return 1;
                }
                return 0;
        });
        var docHeight = document.body.scrollHeight;
        var middle = 0.005 * docHeight;
        window.scrollTo({top: middle, behavior: "smooth"});
      },
      async getOneSnmpDevice(id) {
        const url = `snmp-devices/${id}`;
        try {
          const reply = await SnmpDevicesService.getSnmpDevice(url);
          if (reply.status == 200 && reply.data) {
            this.setting = reply.data;
          }
          // else{
          //   notify("Error getting SNMP Device", "error");
          // }
        }catch {
          notify("Error getting SNMP Device or does not exist", "warning");
          this.$router.push("/snmp-devices");
        }
        
      },
      async getAvailablePorts(id) {
        console.log("Loading, ", this.portsLoading)
        if (this.portsLoading){
          return 1;
        }
        const url = `snmp-devices/${id}/available-ports`;
        try {
          const reply = await SnmpDevicesService.getAllAvailablePorts(url);
          if (reply.status == 200 && reply.data) {

            this.availablePorts = reply.data;
            if (this.availablePorts?.length){
              var array3 = this.availablePorts?.concat(this.setting?.monitoredPorts);

              var set = new Set(array3);

              var array4 = Array.from(set);

              this.availablePorts = array4

              // this.availablePorts = array4.filter(x => x?.oid.includes(`.${this.setting.ip}.`))

              this.availablePorts?.forEach(port => {
                port.active = false
                port.snmp_device_id = this.setting?.id
              });

              this.setting?.monitoredPorts.forEach(port => {
                let index = this.availablePorts?.findIndex(x => x?.name === port?.name)

                if(index >= 0){
                  this.availablePorts[index].active = true
                  this.availablePorts[index].bandwidth_limit_in = port?.bandwidth_limit_in
                  this.availablePorts[index].bandwidth_limit_out = port?.bandwidth_limit_out
                  this.availablePorts[index].announce_duration = port?.announce_duration
                }
              });
              this.availablePorts.sort((a, b) => {
                if (a?.port < b?.port) {
                  return -1;
                }
                if (b?.port < a?.port) {
                  return 1;
                }
                return 0;
              });
              this.availablePorts.sort((a, b) => {
                if (a.active && !b.active) {
                  return -1;
                }
                if (b.active && !a.active) {
                  return 1;
                }
                return 0;
              });
            }
          }
          this.portsLoaded = true;
          this.portsLoading = false;
          // else{
          //   notify("Error getting SNMP Device", "error");
          // }
        }catch {
          notify("Could not get available ports on SNMP Device. Verify details and try again.", "warning");
          // this.$router.push("/snmp-devices");
          this.portsLoaded = true;
          this.portsLoading = false;
        }
        
      },
      async saveSettings() {
        var action = {present: "update", past: "updated"};
        var url = `snmp-devices/${this.snmpDeviceID}`;
        var reply = {};
        try{
          if (!this.isEditing) {
            action = {present: "addition", past: "added"};
            url = `snmp-devices`;
            delete this.setting.id
            this.setting.pollFrequency = Number(this.setting.pollFrequency)
            reply = await SnmpDevicesService.addSnmpDevice(
              url,
              this.setting
            );
          }
          else{
            var newArr = this.availablePorts.map(function(obj) {
              var {id, ...rest} = obj;
              // Just to discard errors
              console.debug(id)
              return rest;
            });

            newArr = newArr.filter(function(obj) {
              return obj.active;
            });

            newArr.forEach(element => {
              element.bandwidth_limit_in = Number(element.bandwidth_limit_in)
              element.bandwidth_limit_out = Number(element.bandwidth_limit_out)
              element.announce_duration = Number(element.announce_duration)
            });

            this.setting.monitoredPorts = newArr;
            this.setting.pollFrequency = Number(this.setting.pollFrequency)

            reply = await SnmpDevicesService.updateSnmpDevice(
              url,
              this.setting
            );
          }
          

          if (reply.status == 200 && reply.data) {
            this.setting = reply.data;
            notify(`SNMP Device ${action.past} successfully`);
            this.$router.push("/snmp-devices");
          }
        }
        catch(error) {
          var text = `SNMP Device ${action.present} failed`;
          notify(text, 'error')
        }
      },
      logOut() {
        this.$store.dispatch("auth/logout");
        this.$router.push("/login");
      },
    },
  };
</script>
<style scoped>
  .bg-secondary {
    background-color: #f7fafc !important;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    scale: 0.7;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
</style>
